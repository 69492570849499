import React from 'react'
import { SEO } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalWorkshop,
  InternalInstructor,
  InternalTestimonial,
  InternalWorkshopTab,
  InternalHowDoYouLearn
} from '../components/Internal'
import { WHATSAPPLINK } from '../constants/routes'
import IconFlower from '../images/common/iconFlower.svg'
import styles from './terapia-floral-sistemica.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Terapia Floral Sistêmica' />
      <InternalHeader className={styles.header} withType>
        <small>Online ou Presencial</small>
        <h1>
          Constelação Familiar
          <br /> <strong>Sistêmica</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent>
            <h2>
              Sobre o <strong>Curso</strong>
            </h2>
            <p>
              A Formação em Constelação Familiar Sistêmica, O Caminho do Coração é um curso prático e vivencial, pensado
              para trazer ao aluno não só seu crescimento profissional mas também a experiência da constelação na sua
              jornada pessoal e terapêutica. O valor do conhecimento está naquilo que ele é capaz de estimular em nós,
              por isso as aulas são baseadas numa metodologia voltada para identificação e solução de conflito com
              ênfase no desenvolvimento profissional e pessoal dos alunos. O aprendizado acontece num ambiente acolhedor
              e voltado para atividades práticas e estudos de casos clínicos decorrentes dessas atividades porque
              aprendemos a partir da vivência e experiência de situações reais e não com exemplos hipotéticos.
            </p>
            <p>
              Segundo Bert Hellinger, a paz começa onde cada um de nós pode ser o que é, onde cada um de nós permite ao
              outro ser tal como é e ficar onde está.
            </p>
            <h2>Objetivo</h2>
            <p>
              Preparar o profissional para identificar conflitos e dissolver os problemas criados por uma postura
              opositora em relação a vida. O objetivo é formar um Constelador capaz de ser um mediador que busca
              enxergar além do que está visível para conduzir seu cliente por um caminho de assentimento e concordância
              com sua história. Ao final do curso o aluno é capaz de não só de identificar os princípios gerais que
              vinculam o indivíduo ao seu sistema familiar e determinam seu desenvolvimento pessoal e profissional, como
              também criar junto ao cliente uma imagem de solução para que ele possa evoluir no modelo de comportamento
              que aprendeu no seu sistema familiar e que levará para todas suas relações sejam elas: sociais, afetivas
              ou profissionais.
            </p>
            <h2>
              O que você <strong>vai aprender</strong>
            </h2>
            <h3>Módulo I</h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Introdução teoria de aplicação local dos Florais de Bach
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A Constelação Familiar e Bert Hellinger
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Campos Mórficos e a Alma familiar
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Ordens do Amor: Hierarquia, Pertencimento e Equilíbrio
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Prática e vivência em constelação
              </dt>
            </dl>
            <br />
            <h3>Módulo II </h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Ordens da Ajuda
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A distinção da consciência, a boa e a má
                consciência e sua atuação no campo
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Genograma, memória transgeracional e Epgnética
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Prática e vivência em constelação
              </dt>
            </dl>
            <br />
            <h3>Módulo III </h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Família de Origem, o movimento em direção aos pais
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A família que criamos, suas dinâmicas e
                emaranhamentos
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Movimento primário interrompido
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Prática e vivência em constelação
              </dt>
            </dl>
            <br />
            <h3>Módulo IV </h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O relacionamento de casal
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Os Filhos da mãe e as filhas do pai
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Separação e alienação parental
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Aborto e adoção
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Prática e vivência em constelação
              </dt>
            </dl>
            <br />
            <h3>Módulo V </h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O significado sistêmico das doenças
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Tabagismo, alcoolismo, vícios, obesidade e distúrbios alimentares
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Prática e vivência em constelação
              </dt>
            </dl>
            <br />
            <h3>Módulo VI </h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O Dinheiro está a serviço da vida
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Êxito na vida e êxito na profissão
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Prática e vivência em constelação
              </dt>
            </dl>
            <br />
            <h3>Módulo VII </h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A Constelação Organizacional
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />O campo empresarial
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Quem responde pelo sistema
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Prática e vivência em constelação
              </dt>
            </dl>
            <br /> <h3>Módulo VIII </h3>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A Dinâmica da Constelação Individual
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />A Dinâmica da Constelação em Grupo
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Bonecos, Âncoras e Figuras
              </dt>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Prática e vivência em constelação
              </dt>
            </dl>
            <br />
            <br />
            <br />
            <InternalHowDoYouLearn />
          </InternalContent>
          <div className='aside'>
            <InternalWorkshop>
              <InternalWorkshopTab>
                <h3>
                  <small>Curso Online</small>
                  Constelação Familiar Sistêmica
                </h3>
                <ul>
                  <li>Aulas online ao vivo pela plataforma digital de fácil interação</li>
                  <li>Aulas 1 vez na semana pela plataforma digital</li>
                  <li>Ênfase para o atendimento online </li>
                  <li>Tire suas dúvidas no momento que surgem ou no grupo de whats</li>
                  <li>Material complementar incluso</li>
                  <li>Aulas com prática e vivencia em constelação Familiar</li>
                  <li>Certificado de conclusão de 150hs</li>
                  <li>Sem Pré-requisito </li>
                  <li>Acesso a plataforma de ensino</li>
                </ul>
                <a
                  href={WHATSAPPLINK}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={`button button--purpleReverse internalWorkshop-button`}
                >
                  Inscrição Curso Online <small></small>
                </a>
              </InternalWorkshopTab>
              <InternalWorkshopTab>
                <h3>
                  <small>Curso Presencial</small>
                  Constelação Familiar Sistêmica
                </h3>
                <ul>
                  <li>Encontros presenciais agendados conforme cronograma anual</li>
                  <li>Aulas 1 final de semana por mês</li>
                  <li>Ênfase para o atendimento presencial</li>
                  <li>Ambiente acolhedor</li>
                  <li>Amplamente prático e vivencial</li>
                  <li>Material complementar incluso</li>
                  <li>Certificado de conclusão</li>
                  <li>Sem Pré-requisito</li>
                  <li>Tire suas dúvidas ao vivo</li>
                </ul>
                <a
                  href={WHATSAPPLINK}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={`button button--purpleReverse internalWorkshop-button`}
                >
                  Inscrição Curso Presencial <small></small>
                </a>
              </InternalWorkshopTab>
            </InternalWorkshop>
            <InternalInstructor />
            <InternalTestimonial />
          </div>
        </div>
      </section>
    </>
  )
}
